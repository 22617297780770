exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-redeem-js": () => import("./../../../src/pages/redeem.js" /* webpackChunkName: "component---src-pages-redeem-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-workshop-js": () => import("./../../../src/pages/workshop.js" /* webpackChunkName: "component---src-pages-workshop-js" */),
  "component---src-templates-map-page-js": () => import("./../../../src/templates/map-page.js" /* webpackChunkName: "component---src-templates-map-page-js" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/post-list.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-variant-page-js": () => import("./../../../src/templates/variant-page.js" /* webpackChunkName: "component---src-templates-variant-page-js" */)
}

